// @import "~bootstrap/scss/bootstrap";
@import './styles/style.scss';


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn-primary:focus{
  border: none !important;
  box-shadow: none !important;
}
.react-datetime-picker__calendar-button, .react-datetime-picker__clear-button{
  display: none !important;
}
.clandr-date{
  color: #ccc;
  font-size: 12px;
  position: absolute;
  right: 5px;
  top: 5px;
}
.y-scroll{
  overflow-y: auto;
}
.post-cont-spacing{
  padding: 30px 15px;
}
.r-spacing{
  padding: 0px !important;
}
body .form-control:focus{
  box-shadow: none !important;
  }
  a:focus{
  border: none !important;
  box-shadow: none !important;
  }
