@font-face {
    font-family: "Conv_Helvetica";
    src: url("../../public/fonts/Helvetica-Normal.eot");
    src: local("☺"), url("../../public/fonts/Helvetica-Normal.woff") format("woff"), url("../../public/fonts/Helvetica-Normal.ttf") format("truetype"), url("../../public/fonts/Helvetica-Normal.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

/** Generated by FG **/

@font-face {
    font-family: "Helvetica-Light";
    src: url("../../public/fonts/4215-font.eot");
    src: local("☺"), 
    url("../../public/fonts/4215-font.woff") format("woff"), 
    url("../../public/fonts/4215-font.ttf") format("truetype"), 
    url("../../public/fonts/4215-font.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}
/*---Helvetica font Url----*/
@font-face {
    font-family: "Helvetica-Light";
    src: url("../../public/fonts/Helvetica/helvetica-light.ttf")  format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Helvetica-Oblique";
    src: url("../../public/fonts/Helvetica/Helvetica-Oblique.ttf")  format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Helvetica-compressed";
    src: url("../../public/fonts/Helvetica/helvetica-compressed.otf")  format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Helvetica-rounded";
    src: url("../../public/fonts/Helvetica/helvetica-rounded-bold.otf")  format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Helvetica-BoldOblique";
    src: url("../../public/fonts/Helvetica/Helvetica-BoldOblique.ttf")  format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Helvetica-Bold";
    src: url("../../public/fonts/Helvetica/Helvetica-Bold.ttf")  format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Helvetica";
    src: url("../../public/fonts/Helvetica/Helvetica.ttf")  format("truetype");
    font-weight: normal;
    font-style: normal;
}
/*---End Helvetica font Url----*/

/*---Gilroy font Url----*/
@font-face {
    font-family: "Gilroy-Light";
    src: url("../../public/fonts/Gilroy/Gilroy-Light.otf")  format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Gilroy-ExtraBold";
    src: url("../../public/fonts/Gilroy/Gilroy-ExtraBold.otf")  format("opentype");
    font-weight: normal;
    font-style: normal;
}
/*---End Gilroy font Url----*/

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;600;700;800;900&display=swap');

@import url('http://fonts.cdnfonts.com/css/gilroy-bold');

// font-family: 'Gilroy-Bold', sans-serif;
// font-family: 'Gilroy-Heavy', sans-serif;
// font-family: 'Gilroy-Light', sans-serif;
// font-family: 'Gilroy-Medium', sans-serif;
// font-family: 'Gilroy-Regular', sans-serif;

a:hover {
    color: #fff !important;
}

body {
    background-color: #1F2124 !important;
    color: #fff !important;
    font-family: "Conv_Helvetica" !important;
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
			-webkit-box-shadow: 0 0 0 30px #151515 inset !important;
			-webkit-text-fill-color: #fff !important;
			transition: background-color 5000s ease-in-out 0s;
    } 
  ::-webkit-scrollbar {
    width: 3px;
    background: #343538;
    height: 5px;
  }
    
    /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
    
    /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4d4d4d;
    border-radius: 0;
  }
  .form-control:focus{
    box-shadow: 0 0 0 2px #3d3d3f;
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote {

    &:before,
    &:after {
        content: "";
        content: none;
    }
}

q {

    &:before,
    &:after {
        content: "";
        content: none;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

// input[type="number"]::-webkit-inner-spin-button,
// input[type="number"]::-webkit-outer-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
// }

.form-control::placeholder {
    color: #4f4e54;
}

body {
    .pt-0 {
        padding-top: 0 !important;
    }

    .text-white-50 {
        color: rgba($color: #fff, $alpha: 0.5);
    }

    .text-black-50 {
        color: rgba($color: #fff, $alpha: 0.5);
    }

    .text-white-70 {
        color: rgba($color: #fff, $alpha: 0.7);
    }

    .text-black-70 {
        color: rgba($color: #fff, $alpha: 0.7);
    }

    p {
        font-size: 0.875rem;
        line-height: 1.3rem;
    }

    .pos-relative {
        position: relative;
        display: block;
    }

    .padd0-responsive {
        @media only screen and (max-width: 575px) {
            padding: 0;
        }
    }

    .date-padding {
        @media only screen and (max-width: 767px) {
            padding: 0px 20px !important;
        }
    }

    .inner_container {
        max-width: 340px;
        margin: 0 auto;

        .price-text {
            font-family: 'Helvetica';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
        }

        @media only screen and (max-width: 767px) {
            padding: 0px 15px;
        }

        @media only screen and (max-width: 575px) {
            max-width: 100%;
        }

        .desk-close-icon {
            position: relative;
            right: -42px;
            cursor: pointer;
        }
    }

    .inner_container_Date_Preview_width {
        max-width: 440px;
    }

    .sub-heading {
        color: #FFFFFF;
        font-size: 16px;
        font-family: "Helvetica-Light";
        letter-spacing: 0.02px;
        line-height: 17.5px;
        font-weight: 300;
    }

    .h5 {
        color: white;
        font-size: 16px;
        letter-spacing: 0.07px;
        line-height: 20.5px;
        font-weight: 700;
        margin-bottom: 0;
    }

    .mb-8 {

        // margin-bottom: 3rem;
        @media only screen and (max-width: 767px) {
            margin-bottom: 5rem;
        }
    }

    .hidden-sm {
        @media only screen and (max-width: 767px) {
            display: none !important;
        }
    }

    &.open-sidebar,
    &.ReactModal__Body--open {
        overflow: hidden;
    }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background: rgba(0, 0, 0, 0.4) !important;

    &:before {
        position: absolute;
        left: 0;
        top: 0;
        background: rgba($color: rgb(0, 0, 0), $alpha: 0.8);
        content: '';
        width: 100%;
        height: 100%;
        filter: blur(4px);
    }
}

.ReactModal__Content {
    background: linear-gradient(231.4deg, rgba(46, 49, 58, 0.80844) 18.16%, rgba(25, 25, 25, 0.831845) 95.56%) !important;
    border: none !important;
    border-radius: 12px !important;
    text-align: center;
}

.pos-relative {
    position: relative;
}

@media only screen and (max-width: 767px) {

    textarea,
    .form-control,
    .form-control:focus,
    .form-control:hover,
    .form-control:active {
        font-size: 16px !important;
    }
}

.mouse-point {
    cursor: pointer;
}

.dropdown-menu{
  background: #2E2F34;
  border: 1px solid #4D4D4D;
  border-radius: 10px;
  .dropdown-item{
    font-family: "Gilroy-Light";
    font-style: normal;
    font-weight: 700;
    font-size: 13.33px;
    line-height: 17px;
    letter-spacing: 0.4px;
    color: #969799;
    &.active, &:active{
      background: transparent;
    }
    &:active{
      color: #F24462;
    }
  }
}

.react-bootstrap-table{
  width: 100%;
  overflow-x: auto;
  .table{
    @media (max-width:1280px) {
      width: 92vw;
    }
  }
}

.form-check-input:focus{
    box-shadow: 0 0 0 0.25rem rgba(242,68,98,0.38);
}

.input-group>.form-control, .input-group>.form-select{
    flex: auto;
    width: auto;
    min-width: auto;
}