.LoginUI{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .authUI{
    max-width: 410px;
    padding: 10px;
    width: 100%;
    text-align: left;
    h2{
      font-family: "Helvetica";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 37px;
      color: #fff;
      letter-spacing: 1px;
      padding-bottom: 15px;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 1.5rem;
    }
    .form-label{
      font-size: 16px;
      letter-spacing: 1px;
      padding-bottom: 7px;
      font-family: "Helvetica-Light";
      color: #FFFFFF;
      margin-bottom: 0;
    }
    .form-control{
      background: #151515;
      border-radius: 8px;
      height: 50px;
      font-family: 'Helvetica';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #FFF;
      border: 1px solid #272c33;
      // margin-bottom: 30px;
      &:focus{
        border: 3px solid #272c33;
        box-shadow: none;
        outline: none;
      }
      &:focus-visible{
        border:3px solid #F24462;
        box-shadow: none;
        outline: none;
      }
    }
    .loginBtn{
      background: #F24462;
      border-radius: 8px;
      border: #F24462;
      font-family: "Helvetica-Light";
      font-style: normal;
      font-weight: 400;
      padding: 16px;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.0666667px;
      color: #FFF;
      width: 100%;
      margin-top: 1rem;
    }
    .form-text{
      display: block;
      margin-top: 7px;
      a{
        cursor: pointer;
        font-family: 'Helvetica-Light';
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.002em;
      }
    }
  }
  &.restPswdUI{
      .form-text{
        font-family: 'Helvetica-Light';
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.002em;
        color: #FFFFFF;
      }
  }
}