footer{
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding:30px 0;

    ul{
        li{
            padding-right: 30px;

            a{
                color: rgba(255, 255, 255, 0.3);
                font-size: 17px;
                text-decoration: none;
                font-family: 'Helvetica-Light';
                letter-spacing: 0.5px;
            }
        }
    }

    p{
        color: rgba(255, 255, 255, 0.3);
        font-size: 16px;
        text-decoration: none;
        font-family: 'Helvetica-Light';
        font-weight: 500;
        letter-spacing: 0.5px;
    }

    @media only screen and (max-width:767px){
        display: none;
    }
}