header {
    nav {
        ul {
            li {
                margin-left: 30px;

                a,
                button {
                    color: $white-color;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 300;
                    letter-spacing: 1px;
                }

                button {
                    background: transparent;
                    border: none;
                    outline: none;

                    &.message_link {
                        position: relative;

                        &>span {
                            margin-right: 8px;
                            position: relative;
                            bottom: 1px;
                        }

                        .top-bages {
                            position: absolute;
                            min-width: 12px;
                            height: 12px;
                            line-height: 10px;
                            border-radius: 50%;
                            background: $primary-color;
                            font-size: 7px;
                            font-weight: 600;
                            top: 0;
                            left: 18px;
                            letter-spacing: 0;
                            padding: 2px;
                            margin-right: 0;
                            bottom: initial;
                        }
                    }
                }

                .user-profile-details {
                    figure {
                        margin: 0;
                        padding: 0;
                        position: relative;
                        padding-right: 15px;
                        cursor: pointer;

                        &>div {
                            top: 3px;
                        }

                        img {
                            border-radius: 50%;
                            object-fit: cover;
                        }

                        @media only screen and (min-width:767px) {

                            &::before,
                            &::after {
                                position: absolute;
                                left: calc(100% - 4px);
                                top: calc(50% - 4px);
                                height: 6px;
                                width: 2px;
                                background: $white-color;
                                content: '';
                                transform: rotate(45deg);
                                pointer-events: none;
                                cursor: pointer;
                            }

                            &::after {
                                left: calc(100% - 7.5px);
                                transform: rotate(-45deg);
                            }
                        }
                    }
                }

                @media only screen and (max-width:767px) {
                    margin-left: 5px;
                }
            }
        }

        .sidebar-nav {
            position: fixed;
            top: 0;
            right: -100%;
            width: 400px;
            height: 100%;
            background: black;
            z-index: 991;
            opacity: 0;
            transition: all 300ms ease-in-out;

            // @media only screen and (max-width:767px){
            //     height: 100%;
            // }
            &::before {
                position: fixed;
                background: rgba($color: #000000, $alpha: 0.6);
                content: '';
                z-index: 1;
                width: 100%;
                height: 100%;
                right: calc(0% + 400px);
                top: 0;
                display: none;
            }

            &.open_nav_menu {
                opacity: 1;
                right: 0;

                &::before {
                    display: block;
                }
            }

            @media only screen and (max-width:767px) {
                width: 300px;

                &:before {
                    right: calc(0% + 300px);
                }
            }
        }
    }

    @media only screen and (max-width:767px) {
        display: none;

        &.loggedin_user {
            display: block;
        }
    }

    .st-logo {
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
    }
}

.inner-page {
    header {
        border-bottom: 0.7px solid #4F4E54;

        @media only screen and (max-width: 767px) {
            border-bottom: 0 !important;
        }
    }
}