$black-color: #000;
$white-color: #fff;
$red-color: #ff0000;
$primary-color: #F24462;
$lightGrey: #CACACA;

@mixin CardBg {
	background: #222326;
	border: 1px solid #4D4D4D;
	border-radius: 13px;
	padding: 20px;
	margin-bottom: 25px;
}
@mixin MinCardBg {
	background: #151515;
	border-radius: 6px;
	border: 1px solid #4D4D4D;
	padding: 9px 6px;
	box-sizing: border-box;
}

@mixin dotDropdown {    
	background: transparent;
	border: none;
	padding: 0;
	font-size: 26px;
	line-height: normal;
	&::after{
		display: none;
	}
	&:focus, &:focus-visible{
		outline: none;
		box-shadow: none;
		border: none;
	}
}